<template>
  <div class="brand-height">
    <!-- Overlay -->
    <div class="body-content-overlay" />

    <div>
      <!-- Searchbar -->
      <div>
        <div class="sticky-brand-filters">
          <div class="d-flex browse-nav-bar">
            <div class="browse-nav-item active">
              BRANDS
            </div>
            <div
              class="browse-nav-item"
              @click="discoverProducts"
            >
              PRODUCTS
            </div>
          </div>
          <!-- Searchbar -->
          <b-row>
            <b-col cols="12">
              <b-input-group class="input-group-merge">
                <b-form-input
                  v-model="filters.search"
                  placeholder="Search Brand"
                  class="search-product pl-3"
                  @input="filterBrands"
                />
              </b-input-group>
              <feather-icon
                icon="SearchIcon"
                size="18"
                class="position-absolute explore-search-icon"
              />
            </b-col>
          </b-row>
          <!-- Dropdown filters -->
          <div
            v-if="filterSetupDone"
            class="row m-0 mt-1"
          >
            <div class="col-6 p-0">
              <b-dropdown
                class="filter-dropdown"
                variant="outline-none"
                block
                no-caret
                menu-class="w-100 filter-dropdown-menu scroll-bar"
                toggle-class="filter-dropdown-toggle"
                no-flip
              >
                <template #button-content>
                  <div
                    class="d-flex justify-content-between"
                    :class="{'has-value': getFilterOptionsCount(filters.departments)}"
                  >
                    <span> Departments {{ getFilterOptionsCount(filters.departments) && `(${getFilterOptionsCount(filters.departments)})` }} </span>
                    <feather-icon icon="ChevronDownIcon" />
                  </div>
                </template>
                <b-dropdown-item
                  v-for="department in filterOptions.departments"
                  :key="department"
                  :active="filters.departments.includes(department)"
                  @click="filterByDropdown('departments' ,department)"
                >
                  <div class="d-flex justify-content-between">
                    <span>
                      {{ department }}
                    </span>
                    <span>
                      <tick-icon v-if="filters.departments.includes(department)" />
                    </span>
                  </div>
                </b-dropdown-item>
              </b-dropdown>
            </div>
            <div class="col-3 p-0">
              <b-dropdown
                class="filter-dropdown ml-8-px"
                variant="outline-none"
                block
                no-caret
                menu-class="w-100 filter-dropdown-menu scroll-bar"
                toggle-class="filter-dropdown-toggle"
                no-flip
              >
                <template #button-content>
                  <div
                    class="d-flex justify-content-between"
                    :class="{'has-value': getFilterOptionsCount(filters.retailPrice)}"
                  >
                    <span> Retail Prices {{ getFilterOptionsCount(filters.retailPrice) && `(${getFilterOptionsCount(filters.retailPrice)})` }} </span>
                    <feather-icon icon="ChevronDownIcon" />
                  </div>
                </template>
                <b-dropdown-item
                  v-for="retailPrice in filterOptions.retailPrices"
                  :key="retailPrice"
                  :active="filters.retailPrice.includes(retailPrice)"
                  @click="filterByDropdown('retailPrice' ,retailPrice)"
                >
                  <div class="d-flex justify-content-between">
                    <span>
                      {{ retailPrice }}
                    </span>
                    <span>
                      <tick-icon v-if="filters.retailPrice.includes(retailPrice)" />
                    </span>
                  </div>
                </b-dropdown-item>
              </b-dropdown>
            </div>
            <div class="col-3 p-0">
              <b-dropdown
                class="filter-dropdown ml-8-px"
                variant="outline-none"
                block
                no-caret
                menu-class="w-100 filter-dropdown-menu scroll-bar"
                toggle-class="filter-dropdown-toggle"
                no-flip
              >
                <template #button-content>
                  <div
                    class="d-flex justify-content-between"
                    :class="{'has-value': getFilterOptionsCount(filters.wholesalePrice)}"
                  >
                    <span> Wholesale Prices {{ getFilterOptionsCount(filters.wholesalePrice) && `(${getFilterOptionsCount(filters.wholesalePrice)})` }} </span>
                    <feather-icon icon="ChevronDownIcon" />
                  </div>
                </template>
                <b-dropdown-item
                  v-for="wholesalePrice in filterOptions.wholesalePrices"
                  :key="wholesalePrice"
                  :active="filters.wholesalePrice.includes(wholesalePrice)"
                  @click="filterByDropdown('wholesalePrice' ,wholesalePrice)"
                >
                  <div class="d-flex justify-content-between">
                    <span>
                      {{ wholesalePrice }}
                    </span>
                    <span>
                      <tick-icon v-if="filters.wholesalePrice.includes(wholesalePrice)" />
                    </span>
                  </div>
                </b-dropdown-item>
              </b-dropdown>
            </div>
          </div>
        </div>
      </div>
      <div
        v-if="filterSetupDone"
        class="brand-section"
      >
        <div>
          <div
            v-for="group in groupedBrands"
            :key="JSON.stringify(group)"
          >
            <div
              v-if="group.groupTitle && group.items && group.items.length"
              class="group-heading"
            >
              <div class="name">
                {{ group.groupTitle }}
                <span
                  v-b-tooltip.hover.bottom.v-primary
                  :title="isRestrictedByBillingCountry ? 'The restriction is applied based on your Billing Address.' : 'The restriction is applied based on your account address, since Billing Address is not set.'"
                >
                  <feather-icon
                    icon="HelpCircleIcon"
                    size="20"
                    class="cursor-pointer text-info"
                  />
                </span>
              </div>
            </div>
            <section
              v-if="group.items && group.items.length"
              :class="itemView"
              class="card-columns brand-explore"
            >
              <div
                v-for="(brand, key) in group.items"
                :key="`${brand._id}-${key}`"
                :class="{'unavailable-brands-opacity': group.isNotAvailable}"
                class="brand-card-container"
              >
                <b-link :to="{ name: 'brand/detail', params: { brandId: brand._id } }">
                  <b-card
                    :id="'brand' + key"
                    class="ecommerce-card brand-card-v2"
                    overlay
                    no-body
                    text-variant="white"
                  >
                    <div class="image">
                      <b-card-img-lazy
                        class="collectionLogo bg-gray img img-responsive fullwidth"
                        :src="brand.logo"
                        :alt="brand.name"
                      />
                    </div>
                    <div class="rounded" />
                    <div
                      class="brand-card card-title h-100"
                    >
                      <b-button
                        variant="outline-none"
                        class="quick-view-btn my-50"
                        @click.prevent="openBrandQuickInfo(brand._id)"
                      >
                        Quick Look <feather-icon icon="EyeIcon" />
                      </b-button>
                    </div>
                  </b-card>
                </b-link>
                <div class="drop-info">
                  <div class="name">
                    {{ brand.name }}
                  </div>
                  <div class="departments mt-2-px">
                    {{ brand.departments && brand.departments.join(', ') || '' }}
                  </div>
                  <div class="d-flex flex-column prices mt-2-px">
                    <div
                      v-if="brand.wholesalePrices"
                      class="d-flex justify-content-between"
                    >
                      <div>WSP: </div> <div class="text-right">
                        {{ brand.wholesalePrices }}
                      </div>
                    </div>
                    <div class="d-flex justify-content-between mt-2-px">
                      <div v-if="brand.retailPrices">
                        RP:
                      </div> <div class="text-right">
                        {{ brand.retailPrices }}
                      </div>
                    </div>
                    <div
                      v-if="brand.minimumOrderValue"
                      class="d-flex justify-content-between mt-2-px"
                    >
                      <div>MOV: </div> <div class="text-right">
                        {{ formatCurrency(brand.minimumOrderValue, brand.currency) }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <tool-kit :toolkit-page="TOOLKIT_PAGE.BRANDS_EXPLORE" />
            </section>
          </div>
        </div>
        <brand-info :brand-profile="brandProfile" />

        <!-- Sidebar -->
        <portal
          to="content-renderer-sidebar-detached-left"
        >
          <shop-filter
            target="brand"
            :filters="filters"
            :filter-options="filterOptionsV2"
            :total-products="totalBrands"
            :mq-shall-show-left-sidebar.sync="mqShallShowLeftSidebar"
            :is-explore="true"
            @update-filters="filterBrands"
          />
        </portal>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BCard,
  BCardImgLazy,
  BCol,
  BFormInput,
  BInputGroup,
  BLink,
  BRow,
  BButton,
  VBTooltip,
  BDropdown,
  BDropdownItem,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { ref, watch } from '@vue/composition-api'
import { useResponsiveAppLeftSidebarVisibility } from '@core/comp-functions/ui/app'
import ShopFilter from '@/views/apps/shop/ShopFilter.vue'
import {
  useShopFiltersSortingAndPagination,
  useShopUi,
} from '@/views/apps/shop/useECommerceShop'
import analytics from '@/@core/utils/analytics'
import constants, {
 MARKUP_FILTER_OPTIONS, retailPrices, TOOLKIT_PAGE, wholesalePrices,
} from '@/constants'
import { FETCH_BRANDS, FETCH_BRANDS_FOR_GUEST, FETCH_BRAND_DETAIL } from '@/store/modules/brand.module'
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'
import { utils, constants as c } from '@kingpin-global/kingpin-utils-frontend'
import { isMarkupIncluded } from '@/@core/utils/utils'

import TickIcon from '@/@core/assets/svg-components/TickIcon.vue'
import store from '../../../../store/index'
import BrandInfo from '../detail/BrandInfo.vue'

const { formatCurrency } = utils

const { COLLECTION_TYPE } = c

export default {
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  components: {
    BRow,
    BCol,
    BCard,
    BCardImgLazy,
    BLink,
    BInputGroup,
    BFormInput,
    BButton,
    ShopFilter,
    FeatherIcon,
    BrandInfo,
    BDropdown,
    BDropdownItem,
    TickIcon,
  },
  data() {
    return {
      TOOLKIT_PAGE,
      brandProfile: {},
      formatCurrency,
    }
  },
  computed: {
    isRetailer() {
      return store.getters.isRetailer
    },
  },
  watch: {
    $route() {
      this.fetchBrands()
    },
  },
  created() {
    analytics.track(constants.TRACKS.PAGES.BRAND_EXPLORE)
  },
  methods: {
    getFilterOptionsCount(options = null) {
      return options?.length || ''
    },
    filterByDropdown(key = '', value = '') {
      if (key && value) {
        if (!this.filters[key].includes(value)) {
          this.filters[key].push(value)
        } else {
          this.filters[key] = this.filters[key].filter(option => option !== value)
        }
      }
      this.filterBrands(this.filters, true)
    },
    async openBrandQuickInfo(brandId = null) {
      if (brandId) {
        store.dispatch(FETCH_BRAND_DETAIL, brandId)
        .then(res => {
          this.brandProfile = res.data.data
          this.$bvModal.show('brand-quick-look')
        })
      }
    },
    discoverProducts() {
      const queryParams = {}
      if (this.isPremium) {
        queryParams.isPremium = this.isPremium
      }
      this.$router.push({ path: '/discover-products', query: queryParams })
      analytics.track(constants.TRACKS.ACTIONS.RETAILER_CLICKS_DISCOVER_PRODUCTS)
    },
  },
  setup(props, { root }) {
    const { sortByBrand } = useShopFiltersSortingAndPagination()
    const { itemView } = useShopUi()

    const { mqShallShowLeftSidebar } = useResponsiveAppLeftSidebarVisibility()
    const brands = ref([])
    const totalBrands = ref(0)
    const groupedBrands = ref({
      availableBrands: { groupTitle: '', items: [] },
      unAvailableBrands: { groupTitle: 'Currently not available in your country', items: [], isNotAvailable: true },
    })
    const isRestrictedByBillingCountry = ref(false)

    // filtering
    const filters = ref({
      search: '',
      departments: [],
      collectionType: [],
      retailPrice: [],
      wholesalePrice: [],
      avgMarkup: [],
    })
    const filterOptions = ref({
      departments: [],
      retailPrices: [],
      collectionTypes: [],
      wholesalePrices: [],
      avgMarkup: Object.keys(MARKUP_FILTER_OPTIONS),
    })
    const filterOptionsV2 = ref({
      collectionTypes: [],
      avgMarkup: Object.keys(MARKUP_FILTER_OPTIONS),
    })
    const filterSetupDone = ref(false)
    const isPremium = ref(false)

    // build filter config
    const filterSetup = brands => {
      filterOptions.value.departments = []
      filterOptions.value.retailPrices = []
      filterOptions.value.collectionTypes = []
      filterOptions.value.wholesalePrices = []
      brands.forEach(brand => {
        // department
        if (brand.departments && Array.isArray(brand.departments)) {
          filterOptions.value.departments = [
            ...filterOptions.value.departments,
            ...brand.departments,
          ]
        }
      })
      filterOptions.value.retailPrices = retailPrices
      filterOptions.value.wholesalePrices = wholesalePrices
      filterOptions.value.departments = [
        ...new Set(filterOptions.value.departments),
      ]
      filterOptions.value.collectionTypes = Object.values(COLLECTION_TYPE).map(op => ({
          text: op.TEXT,
          value: op.LABEL,
        }))
      filterOptionsV2.value.collectionTypes = filterOptions.value.collectionTypes
      filterOptionsV2.value.avgMarkup = filterOptions.value.avgMarkup
      filterSetupDone.value = true
    }
    // fetch brands
    let allBrand = []
    const fetchBrands = async () => {
      isPremium.value = root.$route.name === 'premiumbrands'
        if (store.getters.isGuest) {
          await store.dispatch(FETCH_BRANDS_FOR_GUEST)
        }
        else {
          await store.dispatch(FETCH_BRANDS).then(res => {
            isRestrictedByBillingCountry.value = res?.IS_RESTRICED_BY_BILLING_COUNTRY
          })
        }
      let brandsList = []
      if (isPremium.value) {
        brandsList = store.getters.premiumBrands
      }
      else {
        brandsList = store.getters.brands
      }
      allBrand = brandsList
      brands.value = brandsList
      setBrands(brands.value)
      totalBrands.value = brands.value.length
      filterSetup(brands.value)
    }
    // filter brands
    const filterBrands = (newFilter, resetFilter) => {
      resetFilter = resetFilter || false
      if (newFilter && typeof newFilter === 'object' && resetFilter) {
        filters.value = newFilter
      }
      brands.value = allBrand.filter(item => {
        const include = {
          search: true,
          departments: true,
          collectionType: true,
          retailPrice: true,
          wholesalePrice: true,
          avgMarkup: true,
        }
        // filter for search
        if (filters.value.search && filters.value.search !== '') {
          include.search = item.name
            .toLowerCase()
            .indexOf(filters.value.search.toLowerCase()) > -1
        }
        // filter for departments
        if (filters.value.departments.length) {
          include.departments = filters.value.departments.filter(department => item.departments.includes(department)).length > 0
        }
        // filter for retail price
        if (filters.value.retailPrice.length) {
          include.retailPrice = filters.value.retailPrice.includes(
            item.retailPrices,
          )
        }
        if (filters.value.collectionType.length) {
          include.collectionType = filters.value.collectionType.filter(ct => item.collectionTypes && item.collectionTypes.includes(ct)).length > 0
        }
        if (filters.value.wholesalePrice.length) {
          include.wholesalePrice = filters.value.wholesalePrice.includes(
            item.wholesalePrices,
          )
        }
        if (filters.value.avgMarkup.length) {
          include.avgMarkup = isMarkupIncluded(filters.value.avgMarkup, item.avgMarkup)
        }
        return (
          include.search
          && include.departments
          && include.retailPrice
          && include.collectionType
          && include.wholesalePrice
          && include.avgMarkup
        )
      })
      totalBrands.value = brands.value.length
      // sorting
      brands.value.sort((a, b) => {
        if (sortByBrand.value.value === 'az') {
          return a.name < b.name ? -1 : 1
        }
        if (sortByBrand.value.value === 'za') {
          return a.name > b.name ? -1 : 1
        }
        return 0
      })
      setBrands(brands.value)
    }
    const setBrands = brandList => {
      if (store.getters.isGuest) {
        groupedBrands.value.availableBrands.items = brandList
      } else {
        groupedBrands.value.availableBrands.items = brandList.filter(brand => brand.isBrandAvailableToSell)
        groupedBrands.value.unAvailableBrands.items = brandList.filter(brand => !brand.isBrandAvailableToSell)
      }
    }
    fetchBrands()
    // sort brands
    watch(
      [sortByBrand],
      () => {
        if (filterSetupDone.value) {
          filterBrands()
        }
      },
      {
        deep: true,
      },
    )

    return {
      brands,
      groupedBrands,
      totalBrands,
      mqShallShowLeftSidebar,
      // filters
      filters,
      filterOptions,
      filterOptionsV2,
      filterSetupDone,
      filterBrands,
      sortByBrand,
      // useShopUi
      itemView,
      fetchBrands,
      isRestrictedByBillingCountry,
      isPremium,
    }
  },
}
</script>

<style lang="scss">
@import '~@core/scss/base/pages/app-shop.scss';
@import '~@core/scss/base/pages/app-ecommerce.scss';
</style>

<style lang="scss" scoped>
.item-view-radio-group ::v-deep {
  .btn {
    display: flex;
    align-items: center;
  }
}
</style>

<style scoped lang="scss">
.ecommerce-card {
  .card-title {
    visibility: hidden;
  }

  .card-overlay {
    background-color: rgba(73, 73, 73, 0.55);
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    visibility: hidden;
  }

  &:hover {
    .card-overlay {
      visibility: visible;
    }

    .card-title {
      visibility: visible;
    }
  }

  .image {
    position: relative;
    overflow: hidden;
    padding-bottom: 100%;
  }

  .image img {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}
.brand-height {
  height: inherit;
}

.unavailable-brands-opacity {
  opacity: 0.75;
}
</style>

<style lang="scss">
@import 'src/@core/scss/base/bootstrap-extended/_variables.scss';
.brand-card-container {
  position: relative;

  .brand-card-v2 {
    border: 1px solid $filter-dropdown-border-color;
    &:hover {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      .img {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
      }
    }
  }

  .drop-info {
    top: calc(100% - 40px);
    width: 100%;
    display: none;
    position: absolute;
    background-color: $white;
    z-index: 1;
    border: 1px solid $filter-dropdown-border-color;
    padding: 10px;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    .name,
    .departments {
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;
      color: $text-color-v2;
    }

    .prices {
      font-weight: 700;
      font-size: 12px;
      line-height: 18px;
      color: $body-color;
    }
  }
  &:hover .drop-info {
    display: block;
    box-shadow: 0 4px 25px -25px rgba($black, 0.25);
  }
}
</style>
