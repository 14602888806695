<template>
  <b-modal
    id="brand-quick-look"
    modal-class="custom-modal-variant"
    centered
    hide-footer
    header-class="py-1"
    size="lg"
    @show="showQuickLook"
  >
    <template #modal-title>
      <div class="elements-center color-dark-blue">
        <span v-if="brandProfile.logo">
          <b-img
            :src="brandProfile.logo"
            alt="Brand Logo"
            class="brand-logo-icon"
          />
        </span>
        <span class="ml-1"> {{ brandProfile.entityName || 'Brand' }} </span>
      </div>
    </template>
    <div>
      <b-row v-if="Object.keys(brandProfile).length">
        <b-col
          class="col-12 col-lg-5"
        >
          <b-img
            v-if="brandProfile.logo"
            :src="brandProfile.logo"
            alt="Brand Logo"
            class="brand-logo-preview"
          />
          <div
            v-else
            class="brand-title"
          >
            {{ brandProfile.entityName }}
          </div>
        </b-col>
        <b-col
          class="col-12 pt-1 col-lg-7 pt-lg-0 d-flex justify-content-center flex-column"
        >
          <div
            v-if="brandProfile.yearOfEstablishment"
            class="info-content"
          >
            <span class="label"> Year Established: </span>
            <span class="text"> {{ brandProfile.yearOfEstablishment }}</span>
          </div>
          <div
            v-if="brandProfile.country"
            class="info-content"
          >
            <span class="label"> Country: </span>
            <span class="text"> {{ brandProfile.country }}</span>
          </div>
          <div
            v-if="brandProfile.departments.length"
            class="info-content"
          >
            <span class="label"> Departments: </span>
            <span class="text">
              {{ getDepartments(brandProfile.departments) }}</span>
          </div>
          <div
            v-if="brandProfile.avgWholesalePrice"
            class="info-content"
          >
            <span class="label"> Wholesale Price Range: </span>
            <span class="text"> {{ brandProfile.avgWholesalePrice }}</span>
          </div>
          <div
            v-if="brandProfile.avgRetailPrice"
            class="info-content"
          >
            <span class="label"> Retail Price Range: </span>
            <span class="text"> {{ brandProfile.avgRetailPrice }}</span>
          </div>
          <div
            v-if="brandProfile.minimumOrderValue"
            class="info-content"
          >
            <span class="label"> Minimum Order Value: </span>
            <span class="text">
              {{ formatCurrency(brandProfile.minimumOrderValue, brandProfile.currency) }}</span>
          </div>
        </b-col>
      </b-row>
      <div class="elements-center my-2">
        <b-link
          :to="{ name: 'brand/detail', params: { brandId: brandProfile._id } }"
          class="btn btn-secondary-info text-capitalize"
        >
          Visit {{ brandProfile.entityName || 'Brand' }} Page</b-link>
      </div>
    </div>
  </b-modal>
</template>

<script>
import analytics from '@/@core/utils/analytics'
import constants from '@/constants'
import {
 BModal, VBModal, BLink, BRow, BCol, BImg,
} from 'bootstrap-vue'
import { utils } from '@kingpin-global/kingpin-utils-frontend'

const { formatCurrency } = utils

export default {
  components: {
    BModal,
    BLink,
    BRow,
    BCol,
    BImg,
  },
  directives: {
    'b-modal': VBModal,
  },
  props: {
    brandProfile: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      formatCurrency,
    }
  },
  methods: {
    showQuickLook() {
      analytics.track(constants.TRACKS.PAGES.RETAILER_CLICKS_BRAND_QUICKVIEW)
    },
    getDepartments(departments) {
      if (departments?.length) {
        return departments.join(', ')
      }
      return ''
    },
  },
}
</script>
